import React from "react";
import Lottie from 'react-lottie';
import * as rocketAnimation from '../animations/Astronaut_Ride_Rocket.json'

const Landing = () => {
  const rocketAnimationOption = {
    loop: true,
    autoplay: true,
    animationData: rocketAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="px-[25px] 1xl:px-[144px] overflow-auto relative">
      <div className="flex justify-between items-center absolute top-[36px] left-1/2 -translate-x-1/2 sm:top-[38px] sm:left-[25px] sm:translate-x-0 1xl:left-[144px] z-[3] w-[calc(100%-50px)] 1xl:w-[calc(100%-288px)] 1xl:px-[6px]">
        <div className="flex items-center">
          <img className="w-[37.27px] h-[37.27px] sm:w-[42px] sm:h-[42px] 2xl:w-[2.9166vw] 2xl:h-[2.9166vw]" src="images/logo.png" alt="" />
          <span className="ml-[9px] font-semibold text-[20px] leading-[119.35%] sm:text-[28px] 2xl:text-[1.9444vw]">GenieAI</span>
        </div>
        <button className="flex justify-center items-center min-w-[150px] px-[10px] h-[37px] sm:min-w-[10.4166vw] 2xl:h-[2.7777vw] space-x-2 bg-pink2 font-Inter font-bold text-[14px] leading-[121%] 2xl:text-[0.9722vw] rounded-[5px] 2xl:rounded-[0.3472vw]" onClick={() => window.open("https://MemePad.co")}>
          <img className="2xl:w-[2.0138vw] 2xl:h-[1.3194vw]" src="images/memepad.svg" alt="" />
          <span>MemePad IDO</span>
        </button>
      </div>
      <div className="flex flex-col items-center mobile:items-start mobile:justify-center pt-[389px] sm:pt-[550px] mobile:pt-0 relative mobile:mt-[229px]">
        <div className="mobile:hidden absolute top-[112px] left-1/2 -translate-x-1/2">
          <video className="min-w-[333px] sm:min-w-[500px]" autoPlay loop muted controls={false} playsInline>
            <source src="videos/Hero Animation.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="hidden mobile:flex absolute top-1/2 -translate-y-1/2 right-0 origin-[100%_50%]">
          <video className="w-[624px] 1xl:w-[43.3333vw]" autoPlay loop muted controls={false} playsInline>
            <source src="videos/Hero Animation.mp4" type="video/mp4" />
          </video>
        </div>
        <span className="font-semibold text-[14px] leading-[121%] font-Inter sm:font-bold sm:text-[18px] 2xl:text-[1.25vw]">
          Introducing GenieAI <span className="hidden mobile:inline-block text-pink2">($GenAI)</span>
        </span>
        <span className="mt-2 sm:mt-3 font-bold text-[30px] leading-[36.31px] text-center sm:text-left sm:text-[65px] sm:leading-[115.3846%] z-0 earn-text">Crypto Meets <br/>Generative AI</span>
        <span className="mt-3 sm:mt-[30px] sm:max-w-[381px] font-SFPro sm:font-Inter font-medium text-[14px] leading-[20px] text-center mobile:text-left sm:font-normal sm:text-[22px] sm:leading-[150.0454%] z-0 get-text">
          The first generative AI image creator <br className="block sm:hidden"/>built on{'\u00a0'}
          <svg className="w-[15px] h-[15px] sm:w-[25px] sm:h-[25px] 2xl:w-[1.7361vw] 2xl:h-[1.7361vw] inline-block" width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2224 12.4993L22.1111 9.61042L25 12.4993L22.1111 15.388L19.2224 12.4993ZM0 12.5009L2.88869 9.6122L5.77738 12.5009L2.88869 15.3896L0 12.5009ZM12.4991 19.2253L7.71059 14.4368L4.82234 17.325L12.4991 25.0018L20.1759 17.325L17.2876 14.4368L12.4991 19.2253ZM12.4991 5.77649L17.2876 10.565L20.1759 7.67677L12.4991 0L4.82234 7.67677L7.71059 10.565L12.4991 5.77649Z" fill="#7827DF"/>
            <path d="M9.6449 12.5009L12.4975 9.64822L15.3502 12.5009L12.4975 15.3535L9.6449 12.5009Z" fill="#7827DF"/>
          </svg>
          {'\u00a0'}Binance Smart Chain.
        </span>
        <div className="flex flex-col sm:flex-row justify-center sm:justify-start items-center space-y-[5px] sm:space-x-4 sm:space-y-0 mt-9 sm:mt-[30px] z-0">
          <button className="flex justify-center items-center space-x-2 w-[325px] sm:w-[225px] h-[45px] sm:h-[50px] 2xl:w-[15.625vw] 2xl:h-[3.4722vw] bg-pink2 agree-button rounded-[5px] mobile:rounded-[0.3472vw] font-medium text-[18px] leading-[121%] 2xl:text-[1.25vw] tracking-[0.2px]" onClick={() => window.open("https://t.me/genaicommunity")}>
            <svg className="2xl:w-[1.6666vw] 2xl:h-[1.3888vw]" width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.41718 13.1814L9.02018 18.7654C9.58818 18.7654 9.83418 18.5214 10.1292 18.2284L12.7922 15.6834L18.3102 19.7244C19.3222 20.2884 20.0352 19.9914 20.3082 18.7934L23.9302 1.82141L23.9312 1.82041C24.2522 0.324405 23.3902 -0.260595 22.4042 0.106406L1.11418 8.25741C-0.338822 8.82141 -0.316822 9.63141 0.867178 9.99841L6.31018 11.6914L18.9532 3.78041C19.5482 3.38641 20.0892 3.60441 19.6442 3.99841L9.41718 13.1814Z" fill="#FAF6FF"/>
            </svg>
            <span>Telegram Group</span>
          </button>
          <button className="flex justify-center items-center space-x-2 w-[325px] sm:w-[225px] h-[45px] sm:h-[50px] 2xl:w-[15.625vw] 2xl:h-[3.4722vw] bg-transparent border-2 border-pink2 agree-button rounded-[5px] mobile:rounded-[0.3472vw] font-medium text-[18px] leading-[121%] 2xl:text-[1.25vw] tracking-[0.2px]" onClick={() => window.open("https://pancakeswap.finance/swap?outputCurrency=0x260eA2A1710f14D670A37cfA3f57A9CeD6E795e2&inputCurrency=BNB")}>
            <span>Buy $GenAI</span>
          </button>
        </div>
      </div>
      <div className="flex flex-col mobile:flex-row mobile:justify-between items-center mt-[100px] mobile:mt-[331px] space-y-[35.61px]">
        <video className="w-[325px] sm:w-[400px] mobile:w-[31.25vw]" autoPlay loop muted controls={false} playsInline>
          <source src="videos/Video Web 1.mp4" type="video/mp4" />
        </video>
        <div className="flex flex-col items-center mobile:items-start max-w-[325px] mobile:max-w-[500px] ai-wrapper">
          <span className="font-Inter font-bold text-[30px] leading-[36.31px] mobile:text-[35px] mobile:leading-[121.0285%] text-center mobile:text-left exp-text">AI Image Generation That Works Like Magic 💫</span>
          <span className="mt-[10px] mobile:mt-5 font-Inter font-medium text-[14px] leading-[150%] mobile:font-normal mobile:text-[18px] text-center mobile:text-left exp-content-text">Use the Genie AI telegram bot to create AI generated image and art in seconds. Just tell Genie what you would like it to create, and get your artwork sent back in seconds!</span>
          <button className="flex justify-center items-center space-x-2 mt-[25px] mobile:mt-[22px] w-full mobile:w-[200px] h-[45px] sm:h-[50px] bg-pink2 agree-button rounded-[5px] mobile:rounded-[0.3472vw] font-medium text-[18px] leading-[121%] 2xl:text-[1.25vw] tracking-[0.2px]" onClick={() => window.open("https://t.me/genaicommunity")}>
            <svg className="2xl:w-[1.6666vw] 2xl:h-[1.3888vw]" width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.41718 13.1814L9.02018 18.7654C9.58818 18.7654 9.83418 18.5214 10.1292 18.2284L12.7922 15.6834L18.3102 19.7244C19.3222 20.2884 20.0352 19.9914 20.3082 18.7934L23.9302 1.82141L23.9312 1.82041C24.2522 0.324405 23.3902 -0.260595 22.4042 0.106406L1.11418 8.25741C-0.338822 8.82141 -0.316822 9.63141 0.867178 9.99841L6.31018 11.6914L18.9532 3.78041C19.5482 3.38641 20.0892 3.60441 19.6442 3.99841L9.41718 13.1814Z" fill="#FAF6FF"/>
            </svg>
            <span className="font-Inter">Try Genie Now</span>
          </button>
        </div>
      </div>
      <div className="flex flex-col mobile:flex-row-reverse mobile:justify-between items-center mt-[100px] space-y-[35.61px]">
        <video className="w-[325px] sm:w-[400px] mobile:w-[31.25vw]" autoPlay loop muted controls={false} playsInline>
          <source src="videos/Video Web 2.mp4" type="video/mp4" />
        </video>
        <div className="flex flex-col items-center mobile:items-start max-w-[325px] mobile:max-w-[500px] exp-wrapper">
          <span className="font-Inter font-bold text-[30px] leading-[36.31px] mobile:text-[35px] mobile:leading-[121.0285%] text-center mobile:text-left exp-text">Exciting New Features ⭐️</span>
          <span className="mt-[10px] mobile:mt-5 font-Inter font-medium text-[14px] leading-[150%] mobile:font-normal mobile:text-[18px] text-center mobile:text-left exp-content-text">Genie is constantly adding new features and functionality to give you more options and abilities to create. For example, Genie's "Reaction" feature allows you to overlay an AI generated image over the current chart of any BSC/ETH trading token.</span>
          <button className="flex justify-center items-center space-x-2 mt-[25px] mobile:mt-[22px] w-full mobile:w-[175px] h-[45px] sm:h-[50px] bg-pink2 agree-button rounded-[5px] mobile:rounded-[0.3472vw] font-medium text-[18px] leading-[121%] 2xl:text-[1.25vw] tracking-[0.2px]" onClick={() => window.open("https://t.me/genaicommunity")}>
            <svg className="2xl:w-[1.6666vw] 2xl:h-[1.3888vw]" width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.41718 13.1814L9.02018 18.7654C9.58818 18.7654 9.83418 18.5214 10.1292 18.2284L12.7922 15.6834L18.3102 19.7244C19.3222 20.2884 20.0352 19.9914 20.3082 18.7934L23.9302 1.82141L23.9312 1.82041C24.2522 0.324405 23.3902 -0.260595 22.4042 0.106406L1.11418 8.25741C-0.338822 8.82141 -0.316822 9.63141 0.867178 9.99841L6.31018 11.6914L18.9532 3.78041C19.5482 3.38641 20.0892 3.60441 19.6442 3.99841L9.41718 13.1814Z" fill="#FAF6FF"/>
            </svg>
            <span className="font-Inter">Try Now</span>
          </button>
        </div>
      </div>
      <div className="flex flex-col mobile:flex-row mobile:justify-between items-center mt-[100px] space-y-[35.61px]">
        <img className="w-[325px] sm:w-[400px] mobile:w-[35.8347vw]" src="images/utilities.png" alt="" />
        <div className="flex flex-col items-center mobile:items-start max-w-[325px] mobile:max-w-[500px] exp-wrapper">
          <span className="font-Inter font-bold text-[30px] leading-[36.31px] mobile:text-[35px] mobile:leading-[121.0285%] text-center mobile:text-left exp-text">$GenAI Utilities 🛠</span>
          <span className="mt-[10px] mobile:mt-5 font-Inter font-medium text-[14px] leading-[150%] mobile:font-normal mobile:text-[18px] text-center mobile:text-left exp-content-text">The $GenAI token powers the Genie bot and ecosystem.</span>
          <div className="flex flex-col space-y-2.5 mobile:space-y-5 mt-5 mobile:mt-[25.6px]">
            <div className="flex items-start mobile:items-center space-x-[10px]">
              <div className="mt-1 mobile:mt-0 rounded-full bg-pink2 min-w-[17px] h-[17px]" />
              <span className="font-Inter font-medium text-[14px] leading-[150%] mobile:font-normal mobile:text-[18px] exp-content-text">Holding 10,000 $GenAI gives access to the basic Genie features</span>
            </div>
            <div className="flex items-start mobile:items-center space-x-[10px]">
              <div className="mt-1 mobile:mt-0 rounded-full bg-pink2 min-w-[17px] h-[17px]" />
              <span className="font-Inter font-medium text-[14px] leading-[150%] mobile:font-normal mobile:text-[18px] exp-content-text">Holding 25,000 $GenAI gives access to more advanced features and less limits</span>
            </div>
            <div className="flex items-start mobile:items-center space-x-[10px]">
              <div className="mt-1 mobile:mt-0 rounded-full bg-pink2 min-w-[17px] h-[17px]" />
              <span className="font-Inter font-medium text-[14px] leading-[150%] mobile:font-normal mobile:text-[18px] exp-content-text">Community governance</span>
            </div>
            <div className="flex items-start mobile:items-center space-x-[10px]">
              <div className="mt-1 mobile:mt-0 rounded-full bg-pink2 min-w-[17px] h-[17px]" />
              <span className="font-Inter font-medium text-[14px] leading-[150%] mobile:font-normal mobile:text-[18px] exp-content-text">Staking pools</span>
            </div>
            <div className="flex items-start mobile:items-center space-x-[10px]">
              <div className="mt-1 mobile:mt-0 rounded-full bg-pink2 min-w-[17px] h-[17px]" />
              <span className="font-Inter font-medium text-[14px] leading-[150%] mobile:font-normal mobile:text-[18px] exp-content-text">Future: Revenue burnbacks and deflation</span>
            </div>
        </div>
        </div>
      </div>
      <div className="flex flex-col mobile:flex-row-reverse mobile:justify-between items-center mt-[100px] mobile:mt-[155.96px] space-y-[35.61px]">
        <img className="w-[325px] sm:w-[400px] mobile:w-[34.7222vw]" src="images/tokenomics.png" alt="" />
        <div className="flex flex-col items-center mobile:items-start max-w-[325px] mobile:max-w-[unset]">
          <span className="font-Inter font-bold text-[30px] leading-[36.31px] mobile:text-[35px] mobile:leading-[121.0285%] text-center mobile:text-left exp-text">$GenAI Tokenomics 📊</span>
          <span className="mt-[10px] mobile:mt-5 font-Inter font-medium text-[14px] leading-[150%] mobile:font-normal mobile:text-[18px] text-center mobile:text-left exp-content-text">The $GenAI token has a total supply of 1 Billion Tokens:</span>
          <div className="flex flex-col mobile:flex-row items-center space-y-[25px] mobile:space-y-0 mobile:space-x-[39px] mt-[25px]">
            <img className="w-[231px] h-[231px] mobile:w-[19.6527vw] mobile:h-[19.6527vw]" src="images/piechart.png" alt="" />
            <div className="flex flex-col space-y-5">
              <div className="flex items-center space-x-[10px] 2xl:space-x-[0.7142vw]">
                <div className="min-w-[17px] h-[17px] 2xl:min-w-[1.2142vw] 2xl:h-[1.2142vw] rounded-full bg-blue3" />
                <span className="font-Inter text-[18px] leading-[151.25%] 2xl:text-[1.25vw]">40% - Liquidity Pool</span>
              </div>
              <div className="flex items-center space-x-[10px] 2xl:space-x-[0.7142vw]">
                <div className="min-w-[17px] h-[17px] 2xl:min-w-[1.2142vw] 2xl:h-[1.2142vw] rounded-full bg-purple1" />
                <span className="font-Inter text-[18px] leading-[151.25%] 2xl:text-[1.25vw]">20% - MemePad IDO</span>
              </div>
              <div className="flex items-center space-x-[10px] 2xl:space-x-[0.7142vw]">
                <div className="min-w-[17px] h-[17px] 2xl:min-w-[1.2142vw] 2xl:h-[1.2142vw] rounded-full bg-purple2" />
                <span className="font-Inter text-[18px] leading-[151.25%] 2xl:text-[1.25vw]">20% - Marketing/CEX</span>
              </div>
              <div className="flex items-center space-x-[10px] 2xl:space-x-[0.7142vw]">
                <div className="min-w-[17px] h-[17px] 2xl:min-w-[1.2142vw] 2xl:h-[1.2142vw] rounded-full bg-pink3" />
                <span className="font-Inter text-[18px] leading-[151.25%] 2xl:text-[1.25vw]">10% - Team</span>
              </div>
              <div className="flex items-center space-x-[10px] 2xl:space-x-[0.7142vw]">
                <div className="min-w-[17px] h-[17px] 2xl:min-w-[1.2142vw] 2xl:h-[1.2142vw] rounded-full bg-pink4" />
                <span className="font-Inter text-[18px] leading-[151.25%] 2xl:text-[1.25vw]">10% - Staking Rewards</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mt-[100px] mobile:mt-[156px]">
        <span className="font-Inter font-bold text-[35px] leading-[42.36px] mobile:text-[50px] mobile:leading-[121.02%] roadmap-title">Roadmap 🗺</span>
        <div className="flex flex-col mobile:flex-row justify-center items-center mobile:items-stretch space-x-0 space-y-[15px] mobile:space-x-[30px] mobile:space-y-0 roadmap-wrapper mt-[25px] mobile:mt-[39px]">
          <div className="flex flex-col items-start bg-blue4 border border-pink2 p-[30px] w-[325px] xl:w-[360px] phase 2xl:p-[2.0833vw]">
            <span className="font-Inter font-bold text-[25px] mobile:text-[1.7361vw] leading-[121.04%] text-pink2">Phase 1</span>
            <ul className="mt-3 font-Inter text-[14px] mobile:text-[1.25vw] leading-[150%]">
              <li>Community Created</li>
              <li>GenAI Beta released to Public</li>
              <li>$GenAI launches on MemePad IDO Platform</li>
              <li>$GenAI gets listed on Coinmarketcap & Coingecko</li>
            </ul>
          </div>
          <div className="flex flex-col items-start bg-blue4 p-[30px] w-[325px] xl:w-[360px] phase 2xl:p-[2.0833vw] text-purple3">
            <span className="font-Inter font-bold text-[25px] mobile:text-[1.7361vw] leading-[121.04%]">Phase 2</span>
            <ul className="mt-3 font-Inter text-[14px] mobile:text-[1.25vw] leading-[150%]">
              <li>$GenieAI image competitions</li>
              <li>New GenieAI Model enhancements & features</li>
              <li>Staking Program goes live</li>
              <li>Genie Marketing on socials</li>
            </ul>
          </div>
          <div className="flex flex-col items-start bg-blue4 p-[30px] w-[325px] xl:w-[360px] phase 2xl:p-[2.0833vw] text-purple3">
            <span className="font-Inter font-bold text-[25px] mobile:text-[1.7361vw] leading-[121.04%]">Phase 3</span>
            <ul className="mt-3 font-Inter text-[14px] mobile:text-[1.25vw] leading-[150%]">
              <li>Increased image render quality</li>
              <li>Major partnership announcements</li>
              <li>New AI models launched</li>
              <li>Additional AI functionality</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mt-[100px] mobile:mt-[154px]">
        <span className="text-[30px] leading-[121.0333%] mobile:text-[35px] exp-text text-center">What will you Command with <span className="font-bold">GenieAI?</span></span>
        <span className="mt-[10px] mobile:mt-[5px] text-[14px] leading-[150%] mobile:text-[18px] launch-text text-center">Unlock all features & unlimited image generation for $GenieAI holders.</span>
        <img className="mt-[25px] md:hidden" src="images/portfolio-mobile.png" alt="" />
        <img className="mt-[25px] hidden md:block 2xl:w-[58.0555vw]" src="images/portfolio.png" alt="" />
      </div>
      <div className="flex flex-col items-center mobile:items-start mt-[100.73px] bg-pink2 rounded-[15px] 2xl:rounded-[1.0416vw] px-3 py-[30px] mobile:px-[50px] mobile:py-[56px] relative overflow-hidden">
        <span className="font-Inter font-bold text-[25px] leading-[121.04%] mobile:font-bold 2xl:text-[1.7361vw] text-center">Join the MemePad IDO 🚀</span>
        <span className="mt-[6px] mobile:mt-[10px] font-Inter text-[14px] mobile:text-[18px] mobile:leading-[121%] launch-text">$GenAI is launching on MemePad.</span>
        <div className="relative">
          <img className="my-[25px] mobile:hidden" src="images/banner-mobile.svg" alt="" />
          <div className="absolute top-1/2 right-0 -translate-y-1/2 mobile:hidden w-[232px]">
            <Lottie options={rocketAnimationOption} />
          </div>
        </div>
        <img className="absolute top-0 right-0 h-full hidden mobile:block object-cover" src="images/banner.svg" alt="" />
        <div className="absolute top-0 right-[32px] h-[95%] hidden mobile:block">
          <Lottie options={rocketAnimationOption} />
        </div>
        <div className="flex flex-col mobile:flex-row items-center space-y-[5px] w-full mobile:mt-[25px] mobile:space-y-0 mobile:space-x-[10px]">
          <button className="flex justify-center items-center space-x-2 w-full tiny:w-[300px] h-[45px] sm:h-[50px] mobile:w-[175px] 2xl:h-[3.4722vw] bg-white text-pink2 agree-button rounded-[5px] mobile:rounded-[0.3472vw] font-medium text-[18px] leading-[121%] 2xl:text-[1.25vw] tracking-[0.2px] join-button" onClick={() => window.open("https://MemePad.co")}>
            <span>Join IDO</span>
          </button>
          <button className="flex justify-center items-center space-x-2 w-full tiny:w-[300px] h-[45px] sm:h-[50px] mobile:w-[250px] 2xl:h-[3.4722vw] border-2 border-white bg-transparent agree-button rounded-[5px] mobile:rounded-[0.3472vw] font-medium text-[18px] leading-[121%] 2xl:text-[1.25vw] tracking-[0.2px] tg-button" onClick={() => window.open("https://t.me/genaicommunity")}>
            <svg className="2xl:w-[1.6666vw] 2xl:h-[1.3888vw]" width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.41718 13.1814L9.02018 18.7654C9.58818 18.7654 9.83418 18.5214 10.1292 18.2284L12.7922 15.6834L18.3102 19.7244C19.3222 20.2884 20.0352 19.9914 20.3082 18.7934L23.9302 1.82141L23.9312 1.82041C24.2522 0.324405 23.3902 -0.260595 22.4042 0.106406L1.11418 8.25741C-0.338822 8.82141 -0.316822 9.63141 0.867178 9.99841L6.31018 11.6914L18.9532 3.78041C19.5482 3.38641 20.0892 3.60441 19.6442 3.99841L9.41718 13.1814Z" fill="#FAF6FF"/>
            </svg>
            <span>MemePad Telegram</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landing;