import React, { useContext } from "react";
import { RefContext } from "../contexts/RefContextProvider";

const Footer = () => {
  const { footerRef } = useContext(RefContext);
  return (
    <footer className="flex flex-col items-center mt-[50px] mb-[26px] md:mt-16 md:mb-28" ref={footerRef}>
      <div className="flex items-center space-x-[10px] 2xl:space-x-[0.6944vw]">
        <img className="cursor-pointer 2xl:w-[3.4722vw] 2xl:h-[3.4722vw]" src="images/socials/1.svg" alt="" onClick={() => window.open("https://t.me/genaicommunity")}/>
        <img className="cursor-pointer 2xl:w-[3.4722vw] 2xl:h-[3.4722vw]" src="images/socials/2.svg" alt="" onClick={() => window.open("https://twitter.com/genieaiart")}/>
        <img className="cursor-pointer 2xl:w-[3.4722vw] 2xl:h-[3.4722vw]" src="images/socials/3.svg" alt="" onClick={() => window.open("https://genie.art")}/>
        <img className="cursor-pointer 2xl:w-[3.4722vw] 2xl:h-[3.4722vw]" src="images/socials/4.svg" alt="" />
        <img className="cursor-pointer 2xl:w-[3.4722vw] 2xl:h-[3.4722vw]" src="images/socials/5.svg" alt="" />
      </div>
      <div className="mt-6 w-full h-px bg-pink2" />
      <span className="mt-[26px] font-Inter text-[18px] leading-[151.25%] 2xl:text-[1.25vw] text-pink2">Copyright © Genie AI 2023</span>
    </footer>
  );
};

export default Footer;
